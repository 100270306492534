function Copy() {
  return (
    <svg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.742191 2.03506H3.48647C3.89466 2.03506 4.22866 2.34828 4.22866 2.73097V5.3041C4.22866 5.68683 3.89461 6 3.48647 6H0.742191C0.334001 6 0 5.68678 0 5.3041V2.73097C0 2.34823 0.334051 2.03506 0.742191 2.03506V2.03506ZM2.51353 5.81033e-05H5.25781C5.666 5.81033e-05 6 0.313276 6 0.695962V3.26909C6 3.65183 5.66595 3.965 5.25781 3.965H4.54041V2.73091C4.54041 2.1875 4.06623 1.74289 3.48668 1.74289H1.77149V0.695904C1.77149 0.313171 2.10554 0 2.51368 0L2.51353 5.81033e-05Z'
        fill='#989eae'
      />
    </svg>
  );
}

export default Copy;
